<template>
  <section>

    <b-row>
      <b-col>
        <ag-grid-table
          ref="grid-historico-notificaciones"
          :configUrl="historicoNotificacionesConfig"
          :dataUrl="historicoNotificacionesData"
        ></ag-grid-table>
      </b-col>
    </b-row>
  </section>
</template>
<script>
import
{
  BRow,
  BCol,
  BButton

} from "bootstrap-vue";

import AgGridTable from '@/views/brunacomponents/ag-grid-table/AgGridTable.vue'
import BrunaForm from "@/views/brunacomponents/BrunaForm.vue";

import useApiServices from '@/services/useApiServices.js';




export default {
  data()
  {
    return {



      historicoNotificacionesConfig: useApiServices.historicoNotificacionesConfig,
      historicoNotificacionesData: useApiServices.historicoNotificacionesData,





    };
  },
  components: {
    BRow,
    BCol,
    AgGridTable,
    BrunaForm,
    BButton
  },

  directives: {
  },

  mounted()
  {

  },

  methods: {




  },
};
</script>

<style scoped>
.carga-data {
  margin-top: 4%;
}
</style>

